#subscriptionScreen {
  display: flex;
  flex: 1;
  height: calc(100vh - 86px - 66px);
  box-sizing: border-box;
  margin: 33px 31px;
  position: relative;

  .tableWrapper {
    flex: 1;
    overflow-y: auto;
  }

  .fabButtonIcon {
    position: absolute;
    cursor: pointer;
    bottom: 10px;
    right: 10px;
    width: 48px;
    height: 48px;
  }
}
