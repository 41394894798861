.textInput {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 26px;
  height: 48px;
  min-height: 48px;

  &.multiline {
    .MuiOutlinedInput-root {
      padding: 0;

      textarea {
        height: 100% !important;
        overflow-y: auto !important;
        padding: 16.5px 14px;
        box-sizing: border-box;
      }
    }
  }

  .MuiFormControl-root {
    min-height: 48px;
    height: 100%;
    .MuiOutlinedInput-root {
      background-color: $inputBackground;
    }

    .MuiInputLabel-root {
      transform: translate(14px, 13px) scale(1);
      color: $textGray;
      font-size: $fontSizeInput;
      font-family: PoppinsMedium;

      &.Mui-focused {
        transform: translate(14px, -9px) scale(0.75);
        color: $primary;
      }

      &.MuiFormLabel-filled {
        transform: translate(14px, -9px) scale(0.75);
      }
    }

    .MuiInputBase-root {
      height: 100%;
      min-height: 48px;
      font-family: PoppinsMedium;

      &.MuiOutlinedInput-root {
        align-items: flex-start;

        .MuiInputAdornment-root {
          align-self: center;
        }
      }

      input {
        font-size: $fontSizeInput;
        padding: 0 16.5px;
        height: 100%;
        min-height: 48px;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }

      fieldset {
        border-color: $inputBorder;
      }

      &.Mui-focused {
        fieldset {
          border-color: $primary;
        }
      }
    }
  }

  .errorMessage {
    color: $primary;
    font-size: $fontSizeSmall;
    padding-left: 17px;
    line-height: 16px;
    padding-top: 4px;
    margin: 0px;
    text-align: left;
  }
}
