.imagePicker_mainComponent {
  > span {
    font-size: $fontSizeInput;
    padding-left: 16px;
  }
  .imagePicker {
    display: flex;
    margin-top: 14px;

    .selectedImage {
      min-width: 139px;
      width: 139px;
      height: 139px;
      object-fit: cover;
      border-radius: 5px;
    }

    img {
      margin-right: 16px;
    }

    .minImagesContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: space-between;

      .minImage {
        min-width: 60px;
        height: 60px;
        cursor: pointer;
      }
    }
  }
}
