#screenContainer {
  .registrationForm {
    .formWrapper {
      flex-direction: row;

      > div {
        &:first-of-type {
          margin-right: 18px;
        }
      }

      .inputsRow {
        display: flex;
        width: 420px;

        .textInput:first-of-type {
          margin-right: 17px;
        }
      }

      .errorMessage {
        color: $primary;
        font-size: $fontSizeSmall;
        padding-left: 17px;
        line-height: 16px;
        padding-top: 4px;
        margin: 0px;
      }

      > .errorMessage {
        text-align: center;
      }
    }
  }
  .termsModalBody {
    height: 70vh;
    width: 500;
    overflow-y: scroll;
    margin: 20px
  }
}
