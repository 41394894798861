#trainingsScreen {
  display: flex;
  height: calc(100vh - 86px);
  padding: 0;
  margin: 0;
  &.withSelected {
    flex-direction: column;
    height: auto;
    min-height: calc(100vh - 86px);
  }

  .topContainer {
    display: flex;
    flex: 1;
    height: calc(100vh - 86px - 66px);
    flex-direction: row;
    box-sizing: border-box;
    margin: 33px;

    &.selectedTraining {
      height: 612px;
      max-height: 612px;
    }

    #trainingsListContainer {
      position: relative;

      .trainingRows {
        overflow-y: auto;
        height: 100%;
      }

      .trainingItemRow {
        margin-top: 13px;
      }

      .trainingItemRow ~ .trainingItemRow {
        margin-top: 0;
      }
    }

    #trainingDetailsContainer {
      margin-left: 44px;
      #trainingInfoContainer {
        overflow-y: auto;
      }
    }
  }

  #bottomContainer {
    margin: 0 31px 37px 31px;
    position: relative;

    .tableWrapper {
      max-height: 588px;
      display: flex;
      flex: 1;
    }
  }

  .fabButtonIcon {
    position: absolute;
    cursor: pointer;
    bottom: 58px;
    right: 10px;
    width: 48px;
    height: 48px;
  }

  .trainingTable {
    right: 24px
  }

  .tableFabButton {
    position: absolute;
    cursor: pointer;
    right: 10px;
    width: 48px;
    height: 48px;
  }
}

.progressWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 50px;
}

.loader {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
