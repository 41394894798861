.paginationBar {
  border-top: 1px solid $inputBorder;
  height: 52px;

  .MuiToolbar-root {
    p {
      font-family: PoppinsMedium;
      font-size: $fontSizeSmall;
      color: $textGray;
    }

    .MuiInputBase-root {
      font-family: PoppinsMedium;
      font-size: $fontSizeSmall;

      > div {
        padding-left: 0;
        padding-right: 42.5px;
        border-bottom: 1px solid $inputBorder;
        padding-top: 5px;
      }
    }
  }
}
