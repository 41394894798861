#screenContainer {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow-y: scroll;

  .formWrapper {
    padding: 0 73px;
    display: flex;
    flex-direction: column;

    .hyperLinkComponent {
      padding-bottom: 0;
    }
  }
}
