#root {
  .MuiButton-root {
    background-color: $whiteWithOpacity;
    color: $textBlack;
    box-shadow: 0 3px 6px 0 $shadow;
    border: solid 1px $grayBorder;
    font-family: PoppinsMedium;
    border-radius: 7px;
    white-space: nowrap;
    &:hover {
      background-color: $primary;
      border-color: $primary;
      color: $white;
    }

    &.Mui-disabled {
      background-color: $blackWithOpacity;
      border: solid 1px transparent;
      color: $textGray;
    }
  }
}
