.MuiAlert-message {
  font-family: PoppinsMedium;
}

.deleteModalContent {
  padding: 23px;
  max-width: 590px;

  span {
    line-height: 30px;
    font-size: $fontSizeInput;
  }
}
