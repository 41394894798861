.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba($modalBackground, 0.5);
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  #modalContent {
    max-height: 100%;
    overflow: auto;
    flex: unset;
    background-color: rgba($white, 0.8);
  }

  .buttonsContainer {
    padding: 0 0 17px 17px;
    margin-top: 4px;

    .MuiButton-root {
      margin-right: 18px;
      float: right;
    }
  }
}

#subscriptionScreen #modalContent {
  overflow: visible;
}
