.hyperLinkComponent {
  span {
    font-size: $fontSizeMedium;
  }

  a {
    color: $primary;
    cursor: pointer;
    text-decoration-color: $primaryWithOpacity;
  }
}
