.toggleButtonsWrapper {
  display: flex;
  align-items: center;

  > span {
    font-size: $fontSizeInput;
    padding-right: 15px;
    padding-left: 16px;
  }

  .MuiButtonBase-root {
    border: none;
    margin-right: 8px;
    height: 32px;
    font-size: $fontSizeMedium;
    font-family: PoppinsRegular;
    background-color: rgba($white, 0.4);
    padding: 7px 13px;
    color: $textBlack;

    span {
      color: $primary;
    }

    &:hover {
      background-color: rgba($primary, 0.12);
    }

    &.Mui-selected {
      border: 3px solid $primary;
      font-family: PoppinsSemiBold;
      color: $primary;
      background-color: rgba($white, 0.4);

      &:hover {
        background-color: rgba($primary, 0.12);
      }
    }
  }

  .MuiToggleButtonGroup-grouped:not(:last-of-type) {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border-left: 3px solid transparent;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;

    &.Mui-selected {
      border-left: 3px solid $primary !important;
    }
  }
}
