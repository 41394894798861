.authFormContainer,
#pageTemplate {
  .MuiButtonBase-root {
    .statusIcon {
      border: solid 1px #e5e5e5;
      width: 36px;
      height: 36px;
      box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
    }

    &.Mui-disabled {
      .statusIcon {
        background-color: $blackWithOpacity !important;
        border: solid 1px transparent;
        color: $textGray;
      }
    }
  }
}
