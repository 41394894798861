#surveyInfoContainer {
  padding: 18px 18px 18px 22px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  #surveyTitleSpan {
    font-family: PoppinsMedium;
    font-size: $fontSizeBig;
  }

  .descriptionContainer {
    padding-top: 18px;
    font-size: $fontSizeInput;

    p {
      font-size: $fontSizeMedium;
      color: $textGray;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }

  .contactRow {
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
      margin-right: 19px;
    }

    span {
      font-size: $fontSizeMedium;
    }

    .copyIcon {
      width: 48px;
      height: 48px;
      margin-left: auto;
      margin-right: -8px;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    button {
      &:nth-of-type(1) {
        margin-right: 18px;
      }
    }
  }
}
