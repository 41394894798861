#usersScreen {
    margin: 33px 31px;

    .redDot {
        height: 9px;
        width: 9px;
        background-color: #9b2c2c;
        margin-right: 1em;
        border-radius: 50%;
        display: inline-block;
    }

    .greenDot {
        height: 9px;
        width: 9px;
        background-color: #2c7d31;
        margin-right: 1em;
        border-radius: 50%;
        display: inline-block;
    }
}

#userPaymentsScreen {
    margin: 33px 31px;
}