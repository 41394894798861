#trainingInfoContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 17px;

  .trainingMainInfo {
    display: flex;

    .trainingAvatarContainer {
      border: solid 1px $avatarBorder;
      width: 106px;
      min-width: 106px;
      height: 106px;
      margin-right: 37px;
      background-color: $white;
    }

    .trainingAvatar {
      min-width: 106px;
      max-width: 106px;
      height: 106px;
      margin-right: 37px;
      object-fit: cover;
      border-radius: 5px;
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;

      #trainingTitleSpan {
        font-size: $fontSizeBig;
        line-height: 2.22;
        padding-right: 10px;
      }

      .dateContainer {
        > span {
          color: $textGray;
          font-size: $fontSizeInput;

          &:first-of-type {
            margin-right: 40px;
          }
        }
      }
    }

    .topRightContainer {
      display: flex;
      height: fit-content;
      flex-direction: column;

      .row {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .icon {
          width: 15px;
          height: 15px;
          margin-right: 13.5px;
        }

        .iconText {
          white-space: nowrap;
        }
      }
    }
  }

  .descriptionContainer {
    margin-top: 18px;

    p {
      font-size: $fontSizeMedium;
      line-height: 25px;
      color: $textGray;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }

  .contactContainer {
    padding-bottom: 37px;
    .contactRow {
      display: flex;
      align-items: center;
      margin-bottom: 13px;

      > img {
        width: 18px;
        height: 18px;
        margin-right: 19px;
      }

      > span {
        line-height: 25px;
      }
    }
  }

  .buttonsContainer {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;

    .MuiButton-root {
      margin-left: 18px;
    }
  }
}
