$primary: #ae5a6f;
$primaryWithOpacity: rgba(174, 90, 111, 0.4);
$whiteWithOpacity: rgba(255, 255, 255, 0.4);
$textBlack: #000;
$textGray: #75615e;
$shadow: rgba(0, 0, 0, 0.16);
$inputBorder: #d9d9d9;
$grayBorder: #e5e5e5;
$avatarBorder: #707070;
$modalBackground: #5b5b5b;
$red: #ff5b62;
$white: #fff;
$inputBackground: #fbfaf8;
$activeSubscription: #e3dd8d;
$blackWithOpacity: rgba(0, 0, 0, 0.1);

:export {
  primary: $primary;
  primaryWithOpacity: $primaryWithOpacity;
  whiteWithOpacity: $whiteWithOpacity;
  textBlack: $textBlack;
  textGray: $textGray;
  shadow: $shadow;
  inputBorder: $inputBorder;
  grayBorder: $grayBorder;
  avatarBorder: $avatarBorder;
  modalBackground: $modalBackground;
  red: $red;
  white: $white;
  inputBackground: $inputBackground;
  activeSubscription: $activeSubscription;
  blackWithOpacity: $blackWithOpacity;
}
