.sectionWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: $whiteWithOpacity;

  .labelContainer {
    height: 64px;
    min-height: 64px;
    width: 100%;
    border-bottom: 1px solid $primary;
    padding: 0 9px 0 22.5px;
    box-sizing: border-box;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    > span {
      font-size: $fontSizeInput;
      font-family: PoppinsSemiBold;
    }

    .searchInput {
      margin-left: 150px;
    }
  }
}
