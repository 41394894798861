#pageNotFoundScreen {
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  padding-left: 100px;

  &.withTabs {
    height: calc(100vh - 86px);
  }

  .title {
    font-size: $fontSizeVeryBig;
    margin-bottom: 20px;
  }

  .info {
    font-family: PoppinsRegular;
    font-size: $fontSizeMedium;
  }

  #loginScreenRedirect {
    font-size: $fontSizeMedium;
    font-family: PoppinsRegular;
    text-decoration: none;
  }

  a:-webkit-any-link {
    color: $primary;
  }
}
