.durationPickerContainer {
  display: flex;
  align-items: flex-start;

  > * {
    margin-right: 17px;
  }

  .textInput {
    margin-bottom: 0;
  }

  > span {
    padding-top: 12px;
  }

  > div:nth-of-type(2) {
    display: flex;
    flex: 1;

    .Dropdown-root {
      display: flex;
      flex: 1;

      .Dropdown-control {
        display: flex;
        flex: 1;
      }
    }
  }

  .Dropdown-control {
    border-radius: 5px;
    height: 48px;
    padding: 11px 52px 11px 10px;
    color: $textGray;

    .Dropdown-arrow {
      top: 20px;
    }
  }
}
