.addTraining_formWrapper {
  width: 870px;
  padding: 17px;

  .textInput {
    &:nth-of-type(1n + 2) {
      margin-top: 26px;
    }
  }

  .inputsRow {
    display: flex;
    flex: 1;

    .textInput {
      margin-top: 0;

      &:not(:nth-last-of-type(1)) {
        margin-right: 17px;
      }
    }

    &:nth-last-of-type(1) {
      .textInput {
        margin-bottom: 0;
      }
    }

    &.halfARow {
      width: calc(50% - 8.5px);
    }
  }
}
