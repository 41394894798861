.uploadComponent {
  min-width: 410px;
  height: 139px;
  background-color: $inputBackground;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  img {
    margin-top: 16px;
    height: 24px;
    width: 24px;
  }

  p {
    color: $textGray;
    font-size: $fontSizeInput;
    line-height: 20px;
    margin-bottom: 9px;
    margin-top: 18px;
  }

  .MuiButton-root {
    display: flex !important;
  }
}
