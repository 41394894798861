.trainingItemRow {
  height: 84px;
  min-height: 84px;
  display: flex;
  box-sizing: border-box;
  padding: 8px 18px 9px 18px;
  align-items: center;
  cursor: pointer;

  &:hover,
  &.selected {
    background: rgba($primary, 0.2);
  }

  .trainingAvatarContainer {
    border: solid 1px $avatarBorder;
    min-width: 67px;
    height: 67px;
    margin-right: 20.5px;
    background-color: $white;
  }

  .trainingAvatar {
    min-width: 67px;
    max-width: 67px;
    object-fit: cover;
    height: 67px;
    margin-right: 20.5px;
    border-radius: 5px;
  }

  .detailsContainer {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: space-between;
    align-items: flex-end;
    font-size: $fontSizeMedium;

    .priceContainer {
      display: flex;
      align-items: center;

      .priceTag {
        width: 15px;
        height: 15px;
        margin-right: 13.5px;
      }

      .price {
        white-space: nowrap;
      }
    }

    .type {
      font-family: PoppinsSemiBold;
      font-size: $fontSizeMedium;
    }
  }
}
