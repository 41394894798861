.authFormContainer {
  background-color: $whiteWithOpacity;
  margin-right: 161px;
  min-height: 700px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 $shadow;
  border: solid 1px $grayBorder;

  #logo {
    width: 320px;
    margin-bottom: 66px;
  }

  #form {
    margin-top: 67.5px;

    .hyperLinkComponent {
      align-self: flex-end;
    }
  }

  .formHeader {
    color: $primary;
    font-size: $fontSizeVeryBig;
    height: 33px;
    font-family: PoppinsSemibold;
  }

  #contentStyle {
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 83px;

    .hyperLinkComponent {
      padding-bottom: 60px;
    }
  }
  .MuiButton-root {
    margin-bottom: 26px;
  }
}
