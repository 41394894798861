#modalContent
#getMayCertificatesModalContent {
    .styledDropdownWithSearch{
        .MuiFormControl-root {
            height: 48px;
            .MuiOutlinedInput-root {
              background-color: $inputBackground;
            }
        
            .MuiInputLabel-root {
              transform: translate(14px, 13px) scale(1);
              color: $textGray;
              font-size: $fontSizeInput;
              font-family: PoppinsMedium;
        
              &.Mui-focused {
                transform: translate(14px, -9px) scale(0.75);
                color: $primary;
              }
        
              &.MuiFormLabel-filled {
                transform: translate(14px, -9px) scale(0.75);
              }
            }
            .MuiSelect-select {
                padding: 12px 16.5px;
            }
            .MuiInputBase-root {
              height: 100%;
              font-family: PoppinsMedium;
        
              &.MuiOutlinedInput-root {
                align-items: flex-start;
        
                .MuiInputAdornment-root {
                  align-self: center;
                }
              }
        
              input {
                font-size: $fontSizeInput;
                padding: 0 16.5px;
                height: 100%;
              }
        
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
        
              input:-webkit-autofill:active {
                box-shadow: 0 0 0 30px white inset !important;
                -webkit-box-shadow: 0 0 0 30px white inset !important;
              }
        
              /* Firefox */
              input[type="number"] {
                -moz-appearance: textfield;
              }
        
              fieldset {
                border-color: $inputBorder;
              }
        
              &.Mui-focused {
                fieldset {
                  border-color: $primary;
                }
              }
            }
          }
        
          .errorMessage {
            color: $primary;
            font-size: $fontSizeSmall;
            padding-left: 17px;
            line-height: 16px;
            padding-top: 4px;
            margin: 0px;
            text-align: left;
          }
        }
  }