#salonInfoScreen {
  position: relative;
  height: calc(100vh - 86px);
  width: 100vw;
  display: flex;
  justify-content: center;

  .salonInfoForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .salonAvatar {
      width: 130px;
      height: 130px;
      margin-top: 50px;
      background-color: $primary;
      border-radius: 65px;
      margin-bottom: 55px;
      min-height: 130px;
      cursor: pointer;

      .avatar {
        width: 130px;
        height: 130px;
        border-radius: 65px;
        object-fit: cover;
      }
    }

    .formWrapper {
      display: flex;
      flex-direction: row;

      > div {
        &:first-of-type {
          margin-right: 18px;
        }
      }

      .multiline {
        .MuiFormControl-root {
          min-height: 122px;
        }
      }

      .inputsRow {
        display: flex;
        width: 420px;

        .textInput:first-of-type {
          margin-right: 17px;
        }
      }

      .buttonsContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: auto;

        button {
          &:nth-of-type(1) {
            margin-right: 18px;
          }
        }
      }
    }
  }
}
