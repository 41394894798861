@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
    url("./assets/fonts/Poppins/Poppins-Medium.ttf") format(("truetype"));
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format(("truetype"));
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
    url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format(("truetype"));
  font-weight: 600;
}

@font-face {
  font-family: "PlayfairDisplayMedium";
  src: local("PlayfairDisplayMedium"),
    url("./assets/fonts/PlayfairDisplay/PlayfairDisplay-Medium.ttf") format(("truetype"));
  font-weight: 500;
}

@font-face {
  font-family: "PlayfairDisplayRegular";
  src: local("PlayfairDisplayRegular"),
    url("./assets/fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format(("truetype"));
  font-weight: 400;
}

body {
  margin: 0;
  font-family: PoppinsMedium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
