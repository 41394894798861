.searchInput {
  &.MuiPaper-root {
    height: 48px;
    box-sizing: border-box;
    max-height: 48px;
    display: flex;
    flex: 1;
    max-width: 511px;
    padding: 0 15px;
    border: 2px solid $inputBorder;
    box-shadow: none;
    background-color: $inputBackground;
  }

  input {
    font-family: PoppinsMedium;
    font-size: $fontSizeInput;
    color: $textGray;
    padding-bottom: 3px;

    &::placeholder {
      opacity: 1;
    }
  }

  .searchInputIcon {
    width: 17.5px;
    height: 17.5px;
    margin-right: 10px;
  }
}
