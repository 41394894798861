.subscriptionPopup {
  position: absolute;
  right: 20px;
  top: 50px;
  background-color: rgba($activeSubscription, 0.1);
  border: 2px solid $activeSubscription;
  border-radius: 7px;
  padding: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  img {
    width: 38px;
    height: 38px;
    margin-right: 17px;
  }

  .textContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    .activeSub {
      text-transform: uppercase;
      font-size: $fontSizeMedium;
    }

    .activeTo {
      padding-top: 4px;
      font-size: $fontSizeSmall;
    }
  }
}
