#pageTemplate {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  position: sticky;
  top: 0px;
  flex-direction: column;
  overflow-x: hidden;

  #appBar {
    height: 56px;
    background-color: $white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;

    .logoWrapper {
      display: flex;
      flex: 1;
      align-items: flex-end;

      #logo2 {
        height: 35px;
        align-self: flex-end;
        margin-left: 21px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

    .MuiBox-root {
      border-bottom: none;

      .MuiTabs-root {
        min-height: 56px;

        .MuiTabs-flexContainer {
          min-height: 54px;

          .MuiButtonBase-root {
            color: $textBlack;
            font-family: PoppinsMedium;
            font-size: $fontSizeBig;
            text-transform: none;
            padding: 0 30px;

            span {
              > * {
                color: $primary;
              }
            }

            &.Mui-selected {
              color: $primary;
              font-family: PoppinsSemiBold;
            }
          }
        }

        .MuiTabs-indicator {
          background-color: $primary;
        }
      }
    }

    #appBar_rightside {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;

      > span {
        color: $textBlack;
        font-size: $fontSizeBig;
      }

      @media screen and (max-height: 800px) {
        > span {
          font-size: $fontSizeMedium;
        }
      }

      .MuiButton-root {
        margin-right: 20px;
        margin-left: 53px;
        font-size: $fontSizeMedium;
      }

      #appBar_avatar {
        width: 32px;
        height: 32px;
        margin-right: 14px;
        position: relative;

        #appBar_avatar_photo {
          border-radius: 15px;
          background-color: $primary;
          object-fit: cover;
          max-width: 30px;
          min-width: 30px;
          max-height: 30px;
          min-height: 30px;
        }

        .icon {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  .tabsContent {
    height: calc(100vh - 56px);

    #version {
      color: $textGray;
      padding-left: 9px;
      padding-bottom: 4px;
      text-transform: lowercase;
      box-sizing: border-box;
      height: 30px;
    }
  }
}
