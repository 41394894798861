.MuiPaper-root {
  &.MuiTableContainer-root {
    background-color: transparent;
    box-shadow: none;
  }

  th {
    border-color: $grayBorder;
  }

  .MuiTableHead-root {
    th {
      font-size: $fontSizeTableHeader;
      font-family: PoppinsMedium;
      color: $textGray;
      min-width: 175px;

      &.buttonsHeader {
        width: 100%;
      }
    }
  }

  .MuiTableBody-root {
    th {
      font-size: $fontSizeMedium;
      font-family: PoppinsRegular;
      white-space: nowrap;

      &.buttonsCell {
        padding: 0;
        text-align: end;

        button {
          &:nth-of-type(1) {
            margin-right: 17px;
          }
          margin-right: 11px;
        }
      }
    }

    tr {
      &:hover {
        background: rgba($primary, 0.2);
      }
    }
  }
}

.emptyTable {
  display: flex;
  align-items: center;
  padding: 30px 0;
  flex: 1;
  flex-direction: column;
  font-size: $fontSizeMedium;
}
