#certificatesScreen {
    display: flex;
    height: calc(100vh - 86px);
    padding: 0;
    margin: 0;
    &.withSelected {
      flex-direction: column;
      height: auto;
      min-height: calc(100vh - 86px);
    }
  
    .imageContainer{
      width: 159px;
      height: 159px;
      border-radius: 5px;
      border: solid 1px #d9d9d9;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fbfaf8;
      position: relative;
    }
    .dropzone {
      margin-left: 1em;
      height: 159px;
      border-radius: 5px;
      border: solid 1px #d9d9d9;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fbfaf8;
    }
    .avatar {
      width: 130px;
      height: 130px;
      border-radius: 65px;
      object-fit: cover;
    }

    .subtraction {
      top: 0px;
      right: 0px;
      position: absolute;
      width: 36.8px;
      height: 36.8px;
      margin: 0 0 88px 94.7px;
      object-fit: contain;
      cursor: pointer;
    }

    .topContainer {
      display: flex;
      flex: 1;
      height: calc(100vh - 86px - 66px);
      flex-direction: row;
      box-sizing: border-box;

      &.selectedTraining {
        height: 612px;
        max-height: 612px;
      }

      #certificateInputForm {
        position: relative;
        width: 100%;
        padding: 2%;
        .inputFormContainter{
          padding: 3%;
        }
      }

      #certificatePromoContainer {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 2%;
        .certificate {
          width: 100%;
          height: auto;
        }
        .certificateTitle {
          position: absolute;
          top: 34%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: PoppinsSemibold;
          font-size: 1.1vw;
          font-weight: 600;
          font-stretch: normal;
        }
        .traineeName {
          position: absolute;
          top: 47%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: PlayfairDisplayMedium;
          font-size: 2vw;
          font-weight: 500;
          font-stretch: normal;
        }
        .stylistName {
          position: absolute;
          top: 70%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: PoppinsSemibold;
          font-size: 1.1vw;
          font-weight: 600;
          font-stretch: normal;
        }
        .certificateAvatar {
          position: absolute;
          top: 75%;
          left: 47%;
          width: 3.2vw;
          height: 3.2vw;
          border-radius: 1.6vw;
        }
        .certificateDate {
          position: absolute;
          top: 83%;
          left: 77%;
          transform: translate(-50%, -50%);
          font-family: PoppinsRegular;
          font-size: 0.6vw;
          font-weight: 400;
          font-stretch: normal;
        }
      }
      #trainingDetailsContainer {
        margin-left: 44px;
        #trainingInfoContainer {
          overflow-y: auto;
        }
      }
    }

    #getMayCertificatesModalContent {
      width: 55vw;
      max-height: 75vh;
      padding: 1.5em;
      overflow-y: auto;
      .css-1s2u09g-control {
        border: 1px solid $inputBorder;
        min-height: 48px;
      }
      .css-1pahdxg-control {
        border: 2px solid $primary;
        box-shadow: none;
        min-height: 48px;
      }
      .css-1insrsq-control{
        min-height: 48px;
      }
      .select__option:active{
        background-color: $primaryWithOpacity
      }
      .select__multi-value__remove:hover{
        background-color: $primaryWithOpacity;
        color: $primary;
      }
      .select__input-container{
        color: $textGray;
      }
    }
    .errorMessage {
      color: $primary;
      font-size: $fontSizeSmall;
      padding-left: 17px;
      line-height: 16px;
      padding-top: 4px;
      margin: 0px;
      text-align: left;
    }
  }
  
  .progressWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 50px;
  }
  
  .loader {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
  }
  